<template>
  <div>
    <b-button
      size="sm"
      :class="visible ? null : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="collapse-4"
      @click="visible = !visible"
    >Show/Hide</b-button>
    <b-collapse v-model="visible">
      <b-form-input @input="()=>onChange()" v-model="keyword" placeholder="Search"></b-form-input>

      <b-form-checkbox-group
        class="border-bottom"
        :disabled="loading"
        v-if="item.multi"
        v-model="selected[item.id]"
      >
        <p v-for="child in paginated.after" :key="child.id">
          <b-form-checkbox
            :disabled="loading"
            :value="child"
            @change="(selected) => handler(selected, child)"
          >
            <b v-if="child.is_parent">*</b>
            <b>Name:</b>
            {{ child.name }} |
            <b>Vid:</b>
            {{ child.id }}
          </b-form-checkbox>
          <ListView
            :loading="loading"
            :handler="handler"
            :selected="selected"
            :data="child.childrend"
            :depth="Number(depth) + 1"
          ></ListView>
        </p>
        <b-pagination
          v-model="current"
          v-if="paginated.before.length > 10"
          :total-rows="paginated.before.length"
          :per-page="pageSize"
          size="sm"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </b-form-checkbox-group>

      <b-form-group class="border-bottom" v-else>
        <b-form-radio
          :disabled="loading"
          v-if="!item.multi"
          v-model="selected[item.id]"
          :value="[]"
        >None</b-form-radio>

        <p v-for="child in paginated.after" :key="child.id">
          <b-form-radio
            :disabled="loading"
            v-model="selected[item.id]"
            :name="child.id"
            :value="[child]"
            @change="(selected) => handler(selected, child)"
          >
            <b v-if="child.is_parent">*</b>
            <b>Name:</b>
            {{ child.name }}
            <b>Vid:</b>
            {{ child.id }}
          </b-form-radio>

          <ListView
            :depth="Number(depth) + 1"
            :handler="handler"
            :selected="selected"
            :data="child.childrend"
          ></ListView>
        </p>
        <b-pagination
          v-if="paginated.before.length > 10"
          :total-rows="paginated.before.length"
          v-model="current"
          size="sm"
          :per-page="pageSize"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </b-form-group>
    </b-collapse>
  </div>
</template>

<script>
import Fuse from "fuse.js";

export default {
  beforeCreate: function () {
    this.$options.components.ListView = require("./ListView").default;
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.item.childrend, {
      keys: ["id", "name"],
      threshold: 0.3,
    });
  },
  data() {
    return {
      keyword: "",
      visible: this.item.must,
      current: 1,
      pageSize: 10,
      fuseSearch: null,

    };
  },
  props: ["item", "selected", "handler", "loading", "depth"],
  name: "ArrayPaging",
  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      let arr = this.item.childrend;
      if (this.keyword != "") {
        const searchResult = this.fuseSearch.search(this.keyword)
        arr = searchResult.map(result => result.item);
      }
      return {
        before: arr,
        after: arr.slice(this.indexStart, this.indexEnd),
      };
    },
  },
  methods: {
    onChange() {
      this.current = 1;
    },
    prev() {
      this.current--;
    },
    next() {
      this.current++;
    },
  },
};
</script>
